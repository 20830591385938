<template>
  <div class="contentArea h-full overflow-auto w-full" style="color: #6a7e91">
    <div class="bg-white flex flex-col p-6 h-full">
      <div class="flex flex-col lg:flex-row gap-4 justify-between grow-0 my-5">
        <div class="flex gap-5">
          <button
            class="fill-button fill-button-pri"
            @click="openCreateCampaignModal"
          >
            Create New Campaign
          </button>
          <button
            class="border-button border-button-pri"
            :class="{ 'border-button-disable': !allowClick }"
            :disabled="!allowClick"
            @click="publishCampaign"
          >
            Publish
          </button>
          <button
            class="border-button border-button-pri"
            :class="{ 'border-button-disable': !allowClick }"
            :disabled="!allowClick"
            @click="unPublishCampaign"
          >
            Unpublish
          </button>
          <button
            class="border-button border-button-red"
            :class="{ 'border-button-disable': !allowClick }"
            :disabled="!allowClick"
            @click="openDeleteCampaignModal"
          >
            Delete
          </button>
        </div>
        <div>
          <input class="text-input" v-model="searchText" placeholder="search" />
        </div>
      </div>
      <CampaignTable
        ref="campaignTable"
        @AllowClick="isAllowClick"
        :rowData="rowData"
        :searchText="searchText"
        class="h-full grow bg-red-200"
      />
    </div>
    <!-- Create Campaign Modal -->
    <SmallBlankModal
      v-if="showCreateCampaignModal"
      @close="closeCreateCampaignModal"
    >
      <template v-slot:body>
        <div class="p-4 container">
          <p class="font-semibold text-center">Create Brand</p>
          <div class="flex items-center justify-around">
            <!-- header -->
            <div>
              <div class="img-box">
                <img
                  v-if="header_img_pre"
                  class="object-contain img-element"
                  :src="header_img_pre"
                  alt=""
                />
                <div class="flex items-center justify-center h-full" v-else>
                  <img
                    @click="$refs.uploadHeaderImg.click()"
                    src="@/assets/images/elements/plus.svg"
                    alt=""
                  />
                </div>
                <input
                  style="display: none"
                  ref="uploadHeaderImg"
                  accept="image/png, image/gif, image/jpeg"
                  @change="onFileHeaderSelected($event)"
                  type="file"
                />
              </div>
              <div class="flex justify-center gap-3 font-semibold my-2">
                Header
                <img
                  @click="$refs.uploadHeaderImg.click()"
                  class="pointer"
                  src="@/assets/images/elements/pensil.svg"
                  alt=""
                />
                <img
                  @click="deleteHeaderImg()"
                  class="pointer"
                  src="@/assets/images/icon/del.svg"
                  alt=""
                />
              </div>
            </div>

            <div>
              <div class="img-box">
                <img
                  v-if="footer_img_pre"
                  class="object-contain img-element"
                  :src="footer_img_pre"
                  alt=""
                />
                <div class="flex items-center justify-center h-full" v-else>
                  <img
                    @click="$refs.uploadFooterImg.click()"
                    src="@/assets/images/elements/plus.svg"
                    alt=""
                  />
                </div>
                <input
                  style="display: none"
                  ref="uploadFooterImg"
                  accept="image/png, image/gif, image/jpeg"
                  @change="onFileFooterSelected($event)"
                  type="file"
                />
              </div>
              <div class="flex justify-center gap-3 font-semibold my-2">
                Footer
                <img
                  @click="$refs.uploadFooterImg.click()"
                  class="pointer"
                  src="@/assets/images/elements/pensil.svg"
                  alt=""
                />
                <img
                  @click="deleteFooterImg()"
                  class="pointer"
                  src="@/assets/images/icon/del.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="mb-5 text-center">Maximum image size: 10 MB</div>
          <form @submit.prevent="createCampaign">
            <div class="flex flex-col gap-4 mb-4">
              <div>
                <label for="campaign-input">Campaign name</label>
                <input
                  class="text-input"
                  id="campaign-input"
                  v-model="campaign_name"
                  placeholder="Campaign name"
                  type="text"
                  required
                />
              </div>

              <div class="flex gap-4 w-full">
                <DatePicker
                  class="w-full"
                  mode="date"
                  v-model="start_date"
                  :masks="masks"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <label for="start-date-input">Start date</label>
                    <input
                      class="text-input"
                      id="start-date-input"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Start date"
                      type="text"
                      required
                    />
                  </template>
                </DatePicker>
                <DatePicker
                  class="w-full"
                  mode="date"
                  v-model="end_date"
                  :masks="masks"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <label for="end-date-input">End date</label>
                    <input
                      class="text-input"
                      id="end-date-input"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="End date"
                      type="text"
                      required
                    />
                  </template>
                </DatePicker>
              </div>
            </div>

            <div class="flex justify-center gap-4">
              <button
                type="button"
                @click="closeCreateCampaignModal"
                class="border-button border-button-red"
              >
                Cancel
              </button>
              <button type="submit" class="border-button border-button-pri">
                Create
              </button>
            </div>
          </form>
        </div>
      </template>
    </SmallBlankModal>

    <!-- Delete Campaign Modal -->
    <MiniBlankModal
      v-if="showDeleteCampaignModal"
      @close="closeDeleteCampaignModal"
    >
      <template v-slot:body>
        <div class="flex flex-col gap-4 p-4">
          <p class="font-semibold text-center">
            Are you sure that you want to delete this product?
          </p>

          <div class="flex justify-around">
            <button
              @click="closeDeleteCampaignModal"
              style="background-color: rgb(235, 235, 235)"
              class="border-button border-button-pri"
            >
              Cancel
            </button>
            <button
              @click="deleteCampaign"
              style="background-color: red; color: #ffffff"
              class="border-button border-button-pri"
            >
              Delete
            </button>
          </div>
        </div>
      </template>
    </MiniBlankModal>
  </div>
</template>

<script>
import AgGrid from "../elements/ag-grid-table/AgGridTable.vue";
import CampaignTable from "../elements/ag-grid-table/CampaignTable.vue";
import BlankModal from "../../components/blankModal.vue";
import MiniBlankModal from "../../components/miniblankModal.vue";
import SmallBlankModal from "../../components/smallblankModal.vue";
import axios from "../../axios";
import { DatePicker } from "v-calendar";
import { baseurl } from "../../util/baseUrl";
import moment, { defaultFormat } from "moment";
import Compressor from "compressorjs";

export default {
  // async created() {

  // },
  data() {
    return {
      searchText: "",
      rowData: [],
      showCreateCampaignModal: false,
      showDeleteCampaignModal: false,

      all_campaign: null,

      header_img: null,
      header_img_pre: null,
      header_img_url: null,
      footer_img: null,
      footer_img_pre: null,
      footer_img_url: null,

      campaign_name: null,

      masks: {
        input: "DD/MM/YYYY",
      },
      start_date: null,
      end_date: null,

      allowClick: false,
    };
  },
  beforeMount() {
    this.getAllCampaign();
    console.log(this.rowData);
  },

  methods: {
    //   setwatcher(){
    //   console.log("set-watcher");
    //   this.$watch(
    //       () => {
    //         return this.$refs.campaignTable.isSelected;
    //       },
    //       (val) => {
    //         console.log("tick:",val);
    //         this.allowClick = val;
    //       }
    //   )
    // },
    isAllowClick(val) {
      // console.log('isAllowClick',val);
      this.allowClick = val;
    },

    openCreateCampaignModal() {
      this.showCreateCampaignModal = true;
    },

    openDeleteCampaignModal() {
      this.showDeleteCampaignModal = true;
    },

    closeDeleteCampaignModal() {
      this.showDeleteCampaignModal = false;
    },

    closeCreateCampaignModal() {
      this.showCreateCampaignModal = false;
    },

    async onFileHeaderSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log(file);
        this.header_img = file;
        this.header_img_pre = await getBase64(file);
      }
      e.target.value = null;
    },
    async onFileFooterSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log(file);
        this.footer_img = file;
        this.footer_img_pre = await getBase64(file);
      }
      e.target.value = null;
    },

    deleteFooterImg() {
      this.footer_img = null;
      this.footer_img_pre = null;
      this.foorer_img_url = null;
    },
    deleteHeaderImg() {
      this.header_img = null;
      this.header_img_pre = null;
      this.header_img_url = null;
    },

    async deleteCampaign() {
      let rows = await this.$refs.campaignTable.getSelectedRowData();
      console.log("rows:", rows);
      let campaign_arr = rows.map((each) => {
        return each.campaign_detail.campaign_id;
      });
      await axios
        .delete(`${baseurl()}/api/admin/deleteCampaignMultiple`, {
          data: {
            campaign_id: campaign_arr,
          },
        })
        .then((res) => {
          console.log(res);
          this.showDeleteCampaignModal = false;
          this.getAllCampaign();
        })
        .catch((err) => {
          console.log(err);
          this.showDeleteCampaignModal = false;
        });
    },

    async createCampaign() {
      this.$vs.loading({ color: "#DDDDDD" });
      let header_url = this.header_img
        ? await uploadFileCampaign(
            "header",
            this.campaign_name,
            this.header_img
          )
        : null;
      let footer_url = this.footer_img
        ? await uploadFileCampaign(
            "footer",
            this.campaign_name,
            this.footer_img
          )
        : null;

      let data = {
        campaign_name: this.campaign_name,
        start_date: this.start_date,
        end_date: this.end_date,
        line_keyword: "",
        footer_img: footer_url,
        header_img: header_url,
        web_url: "",
      };

      await axios
        .post(`${baseurl()}/api/admin/createCampaign`, data)
        .then(async (res) => {
          console.log(res);
          await this.getAllCampaign();
          this.showCreateCampaignModal = false;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log(err);
          this.showCreateCampaignModal = false;
          this.$vs.loading.close();
        });
    },

    async getAllCampaign() {
      try {
        let res = await axios.get(`${baseurl()}/api/admin/getAllCampaign`);
        this.all_campaign = res.data.campaign;
        console.log(this.all_campaign);
        this.rowData = [];
        await this.all_campaign.map((campaign, index) => {
          this.rowData.push({
            no: index + 1,
            campaign_name: campaign.campaign_name,
            start_date: moment(campaign.start_date).format("DD/MM/YY"),
            end_date: moment(campaign.end_date).format("DD/MM/YY"),
            is_publish: {
              campaign_id: campaign.campaign_id,
              is_publish: campaign.is_publish,
            },
            campaign_detail: {
              campaign_id: campaign.campaign_id,
            },
          });
        });
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },

    async publishCampaign() {
      try {
        this.$vs.loading({ color: "#DDDDDD" });
        let rows = await this.$refs.campaignTable.getSelectedRowData();
        console.log("rows:", rows);
        let campaign_id_publish = rows.map((each) => {
          return {
            campaign_id: each.campaign_detail.campaign_id,
            is_publish: true,
          };
        });
        await axios.put(
          `${baseurl()}/api/admin/updatePublishCampaignMultiple`,
          {
            campaign_id_publish,
          }
        );
        await this.getAllCampaign();
        this.$vs.loading.close();
      } catch (error) {
        console.log(error);
        this.$vs.loading.close();
      }
    },

    async unPublishCampaign() {
      try {
        this.$vs.loading({ color: "#DDDDDD" });
        let rows = await this.$refs.campaignTable.getSelectedRowData();
        console.log("rows:", rows);
        let campaign_id_publish = rows.map((each) => {
          return {
            campaign_id: each.campaign_detail.campaign_id,
            is_publish: false,
          };
        });

        await axios.put(
          `${baseurl()}/api/admin/updatePublishCampaignMultiple`,
          {
            campaign_id_publish,
          }
        );

        await this.getAllCampaign();
        this.$vs.loading.close();
      } catch (error) {
        console.log(error);
        this.$vs.loading.close();
      }
    },
  },

  async mounted() {
    this.$vs.loading.close();
    await this.$store.dispatch("general/push_activePage", {
      name: "Campaign management",
      slug: "campaign",
    });
  },

  components: {
    AgGrid,
    CampaignTable,
    BlankModal,
    MiniBlankModal,
    SmallBlankModal,
    DatePicker,
  },
};
const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};

async function compressImage(file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 1,
      maxHeight: 12000,
      success(result) {
        resolve(result);
      },
      error(e) {
        reject(e);
      },
    });
  });
}

const uploadFileCampaign = async (type, campaignname, file) => {
  let fd = new FormData();
  if (type) {
    fd.append("img_type", type);
  }
  const fileforupload = await compressImage(file);
  fd.append("campaign_name", campaignname);
  fd.append("file", fileforupload);
  try {
    let res = await axios.post(`${baseurl()}/api/website/uploadImg`, fd);
    return res.data.url;
  } catch (error) {
    throw error;
  }
};
</script>

<style lang="scss" scoped>
.img-box {
  border: 1px solid #6a7e91;
  border-radius: 10px;
  width: 15rem;
  height: 20rem;
  background-color: #ffffff;
}

.img-element {
  width: 14.8rem;
  height: 19.8rem;
}
</style>
